/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
.CoreApp {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  align-items: stretch;
  overflow: auto;
}
.CoreApp header {
  position: sticky;
  top: 0;
  z-index: 100;
}

.MainContent {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
}