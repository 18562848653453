/**
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
.intent-mouse *:focus {
  outline: none !important;
}
.intent-mouse .il-header-logo:focus,
.intent-mouse .il-header-title:focus {
  background-color: transparent;
}
.intent-mouse .il-header-actions .il-header-action.il-header-action--simple-action:focus {
  background-color: transparent;
}
.intent-mouse .il-header-actions .il-header-action.il-header-action--dropdown-action:focus {
  background-color: transparent;
}
.intent-mouse .il-header-actions .il-header-action.il-header-action--dropdown-action.il-header-action--with-text:focus {
  background-color: transparent;
}

.AppSwitcher div[class*=il-header-action__desktop] ul[class*="bx--overflow-menu-options bx--overflow-menu--flip bx--overflow-menu-options--open"] {
  top: 44px !important;
}

.mobileContainer {
  display: none;
}

@media only screen and (max-width: 672px) {
  .desktopContainer {
    display: none;
  }

  .mobileContainer {
    display: block;
  }
}