/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */
/**
* Copyright 2020, IntraLinks, Inc. All rights reserved.
*/
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
.intent-mouse *:focus {
  outline: none !important;
}
.intent-mouse .il-header-logo:focus,
.intent-mouse .il-header-title:focus {
  background-color: transparent;
}
.intent-mouse .il-header-actions .il-header-action.il-header-action--simple-action:focus {
  background-color: transparent;
}
.intent-mouse .il-header-actions .il-header-action.il-header-action--dropdown-action:focus {
  background-color: transparent;
}
.intent-mouse .il-header-actions .il-header-action.il-header-action--dropdown-action.il-header-action--with-text:focus {
  background-color: transparent;
}

.modalTime {
  display: inline-block;
  text-align: center;
  width: 2.5rem;
}